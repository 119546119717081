body {
    background: url('/img/bg.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin: 0 auto;
}

.merry-christmas-text {
    font-size: 100px;
    height: 200px;
    text-align: center;
    color: white;
    text-shadow: 1px 1px 2px white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.merry-christmas-text2 {
    font-size: 25px;
    text-align: center;
    margin: 0 0 50px 0;
    color: white;
    text-shadow: 1px 1px 2px white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.build-by-secret-santa {
    font-size: 25px;
    margin: 50px 0 50px 0;
    text-align: center;
    color: white;
    text-shadow: 1px 1px 2px white;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
