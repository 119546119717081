body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    background: url('/img/bg.png') no-repeat center center fixed;
    background-size: cover;
    margin: 0 auto;
}

.merry-christmas-text {
    font-size: 100px;
    height: 200px;
    text-align: center;
    color: white;
    text-shadow: 1px 1px 2px white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.merry-christmas-text2 {
    font-size: 25px;
    text-align: center;
    margin: 0 0 50px 0;
    color: white;
    text-shadow: 1px 1px 2px white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.build-by-secret-santa {
    font-size: 25px;
    margin: 50px 0 50px 0;
    text-align: center;
    color: white;
    text-shadow: 1px 1px 2px white;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.calendar-container {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding-left: 120px;
    padding-right: 120px;
    justify-items: center;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}
.calendar-day {
    text-align: center;
    background-color: #e33052;
    width: 180px;
    height: 180px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    position: relative;
}

.calendar-day p {
    font-size: 100px;
    color: #ffffff;
    position: relative;
}

.opened-day img {
    /* animation */
    transition: all 1s ease-in-out;

    position: relative;
    border: 1px solid #dddddd;
    padding: 5px;
    background-color: #888888;
}

.opened-day-no-central {
    width: 130px;
    height: 130px;
    z-index: 100;
}

.opened-day-central {
    width: 500px;
    height: 500px;
    z-index: 101;
}


.oou-text {
    font-size: 20px;
    padding: 5px;
    position: absolute;
    top: -10px;
    left: -10px;
    min-width: 100px;
    border-radius: 10px;
    background-color: white;
    color: #e33052;
}

@-webkit-keyframes fadeout {
    0% {
        opacity: 1;
        visibility: visible;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.fadeout {
    -webkit-animation: fadeout 3s linear;
    visibility: hidden;
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 1;
    }
}

.fadein {
    -webkit-animation: fadein 3s linear;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

